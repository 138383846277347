import algoliasearch from "algoliasearch";
import autocomplete from "autocomplete.js";


const client = algoliasearch(process.env.ALGOLIA_APPLICATION_ID, process.env.ALGOLIA_SEARCH_ONLY_API_KEY);
const specialty = client.initIndex('Specialty');
const expertise = client.initIndex('Expertise');
const expertise_registered_doctors = client.initIndex('Expertise_registered_doctors');


const initAlgoliaSearch = () => {
  // partie pour le premier form (hors mobile version)
  if (document.querySelector('#aa-search-input')) {
    // initialize autocomplete on search input (ID selector must match)
    autocomplete('#aa-search-input', { hint: true, minLength: 2 }, [
      {

        // define the number of hits per page that you would like to receive
        source: autocomplete.sources.hits(specialty, { hitsPerPage: 5 }),

        // value to be displayed in the input control after a user's suggestion selection
        displayKey: 'specialty',

        // a hash of template(s) used when rendering dataset
        templates: {
                  suggestion({_highlightResult}) {
                    return `<span>${_highlightResult.specialty.value}</span>`;
            }
          }
      },
      {

        // define the number of hits per page that you would like to receive
        source: autocomplete.sources.hits(expertise_registered_doctors, { hitsPerPage: 2 }),

        // value to be displayed in the input control after a user's suggestion selection
        displayKey: 'Expertise',

        // a hash of template(s) used when rendering dataset
        templates: {
                  suggestion({_highlightResult}) {
                    return `<span>${_highlightResult.Expertise.value}</span>`;
            }
        }
      }
    ]);
  }
  // partie pour le second form (mobile version)
  if (document.querySelector('#aa-search-input2')) {
    // initialize autocomplete on search input (ID selector must match)
    autocomplete('#aa-search-input2', { hint: true, minLength: 2 }, [
      {

        // define the number of hits per page that you would like to receive
        source: autocomplete.sources.hits(specialty, { hitsPerPage: 5 }),

        // value to be displayed in the input control after a user's suggestion selection
        displayKey: 'specialty',

        // a hash of template(s) used when rendering dataset
        templates: {
                  suggestion({_highlightResult}) {
                    return `<span>${_highlightResult.specialty.value}</span>`;
            }
          }
      },
      {

        // define the number of hits per page that you would like to receive
        source: autocomplete.sources.hits(expertise_registered_doctors, { hitsPerPage: 2 }),

        // value to be displayed in the input control after a user's suggestion selection
        displayKey: 'Expertise',

        // a hash of template(s) used when rendering dataset
        templates: {
                  suggestion({_highlightResult}) {
                    return `<span>${_highlightResult.Expertise.value}</span>`;
            }
        }
      }
    ]);
  }
  // partie pour le form search-specialty sans lien (toutes les versions)
  if (document.querySelector('#aa-search-input-search-specialty')) {
    // initialize autocomplete on search input (ID selector must match)
    autocomplete('#aa-search-input-search-specialty', { hint: true, minLength: 2 }, [
      {
        // define the number of hits per page that you would like to receive
        source: autocomplete.sources.hits(specialty, { hitsPerPage: 15 }),

        // value to be displayed in the input control after a user's suggestion selection
        displayKey: 'specialty',

        // a hash of template(s) used when rendering dataset
        templates: {
                  suggestion({_highlightResult}) {
                    return `<p class="spec-color">${_highlightResult.specialty.value}</p>`;

            // 'suggestion' templating function used to render a single suggestion
            // suggestion: function(suggestion) {
            //     return '<span>' + suggestion._highlightResult.first_name.value + '</span>' + '<span>' + suggestion._highlightResult.specialty.value + '</span>';;

            }
        }
      }
    ]);
  }
  // partie pour le form search-expertise sans lien (toutes les versions)
  for (let i = 1; i < 11; i++) {
    if (document.querySelector('#aa-search-input-search-expertise' + i)) {
      // initialize autocomplete on search input (ID selector must match)
      autocomplete('#aa-search-input-search-expertise' + i, { hint: true, minLength: 2 }, [
        {
          // define the number of hits per page that you would like to receive
          source: autocomplete.sources.hits(expertise, { hitsPerPage: 30 }),

          // value to be displayed in the input control after a user's suggestion selection
          displayKey: 'Expertise',

          // a hash of template(s) used when rendering dataset
          templates: {
                    suggestion({_highlightResult}) {
                      return `<p class="spec-color">${_highlightResult.Expertise.value}</p>`;

              // 'suggestion' templating function used to render a single suggestion
              // suggestion: function(suggestion) {
              //     return '<span>' + suggestion._highlightResult.first_name.value + '</span>' + '<span>' + suggestion._highlightResult.expertise.value + '</span>';;

              }
          }
        }
      ]);
    }
  }
};

export { initAlgoliaSearch };
