import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const villeGeoFindMe = () => {
      // const citySearchInput = document.querySelector('.city-search-geo');
      const locSearchInput = document.querySelector('.ville-geo-search');

      function success(position) {
        document.querySelector('#ville-geolocation').style.display = 'none';
        document.querySelector('.loading-gif').style.display = 'block';
        const latitude  = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(latitude);
        console.log(longitude);
        locSearchInput.value = "[" + latitude + ", " + longitude + "]";
        document.querySelector('.ville_geolocation').submit();

      }

      function error() {
        alert("La géolocalisation n'est pas supportée par votre navigateur internet, ou elle n'est pas activée.");
      }

      if(!navigator.geolocation) {
        alert("La géolocalisation n'est pas supportée par votre navigateur internet.");
      } else {
        // citySearchInput.value = 'Locating…';
        navigator.geolocation.getCurrentPosition(success, error);
      }
    }

    document.querySelector('#ville-geolocation').addEventListener('click', villeGeoFindMe);

  }
}
