const copyClipboardLinks = () => {
  if (document.querySelectorAll(".posturl")) {
    const posturls = document.querySelectorAll(".posturl");
    const copyclips = document.querySelectorAll(".copyclip");
    for ( var i = 0; i < posturls.length; i++ ) (function(i){
      copyclips[i].onclick = function() {
        posturls[i].select();
        document.execCommand("copy");
        $(this).text('Copié !');
      }
    })(i);
  }
  else if (document.querySelectorAll(".posturlmobile")) {
    const posturlmobiles = document.querySelectorAll(".posturlmobile");
    const copyclipmobiles = document.querySelectorAll(".copyclipmobile");
    for ( var i = 0; i < posturlmobiles.length; i++ ) (function(i){
      copyclipmobiles[i].ontouchstart = function() {
        posturlmobiles[i].select();

        document.execCommand("copy");
        // $(this).text('Copié !');
      }
    })(i);
  }
}


export { copyClipboardLinks };
