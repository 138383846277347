import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const form = this;
    const submitButton = form.element.querySelector('input[type=submit]');
    const trixeditor = this.element.querySelector('trix-editor');
    if ((window.innerWidth >= 576 ))  {
      console.log(form);
      trixeditor.addEventListener("keydown", event => {
        if (document.querySelector('[data-trix-active]') === document.querySelector('[data-trix-attribute="bullet"]') || document.querySelector('[data-trix-active]') === document.querySelector('[data-trix-attribute="number"]')) {
          if (event.shiftKey && event.key == "Enter") {
            event.target.editor.recordUndoEntry('Shift+Enter');
            event.target.editor.insertLineBreak();
            event.preventDefault();
          }
        }
        if (event.keyCode == 13 && !event.shiftKey) {
          event.preventDefault();
          if (form.element.querySelector('.remote-true') && form.element.querySelector('.remote-true') === submitButton) {
            Rails.fire(trixeditor.inputElement.form, 'submit')
          } else {
            submitButton.click();
          }
        }
      })
    }
  }
}
