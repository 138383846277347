function insertTagsMultiple (texte, inputTag) {
  if (inputTag.value.includes(',' + texte.innerHTML)) {
    inputTag.value = remove_character((',' + texte.innerHTML), inputTag.value);
    texte.classList.remove(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.add(`tag-at-first`);
  } else if (inputTag.value.includes(texte.innerHTML)) {
    inputTag.value = remove_character(texte.innerHTML, inputTag.value);
    texte.classList.remove(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.add(`tag-at-first`);
  }
  else if (inputTag.value === '') {
    inputTag.value = texte.innerHTML;
    texte.classList.add(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.remove(`tag-at-first`);
  } else {
    inputTag.value = inputTag.value + ',' + texte.innerHTML;
    texte.classList.add(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.remove(`tag-at-first`);
  }
}

function remove_character(str_to_remove, str) {
  let reg = new RegExp(str_to_remove)
  return str.replace(reg, '')
}


const clickEventTagMultiple = () => {
  const competenceTags = [];
  for ( var j = 0; j < 20; j++ ) (function(j){
    competenceTags[j] = document.querySelectorAll(`.competencetag${j}`);
  })(j);
  if ((window.innerWidth > 575.98 ) && (document.querySelectorAll(".category-input-comment")))  {
    const categoryInputs = document.querySelectorAll(".category-input-comment");
    for ( var j = 0; j < categoryInputs.length; j++ ) (function(j){
      for ( var h = 0; h < competenceTags.length; h++ ) (function(h){
        competenceTags[h][j].onclick = function() {
         competenceTags[h][j].addEventListener('click', insertTagsMultiple(competenceTags[h][j], categoryInputs[j]));
        }
      })(h);
    })(j);
  }
  else if ((window.innerWidth <= 575.98 ) && (document.querySelectorAll(".category-input-comment"))) {
    const categoryInputs = document.querySelectorAll(".category-input-comment");
    for ( var j = 0; j < categoryInputs.length; j++ ) (function(j){
      for ( var h = 0; h < competenceTags.length; h++ ) (function(h){
        competenceTags[h][j].ontouchstart = function() {
         competenceTags[h][j].addEventListener('touchstart', insertTagsMultiple(competenceTags[h][j], categoryInputs[j]));
        }
      })(h);
    })(j);
  }
}

export { clickEventTagMultiple };
