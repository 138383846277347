import intlTelInput from 'intl-tel-input';

const processTelephoneInput = (event) => {
  function getIp(callback) {
   fetch('https://ipinfo.io/json?token=<votre token>', { headers: { 'Accept': 'application/json' }})
     .then((resp) => resp.json())
     .catch(() => {
       return {
         country: 'fr',
       };
     })
     .then((resp) => callback(resp.country));
  }
  if (event) {
    if (!event.target.form.querySelector('.demander-numero-tel') || (event.target.form.querySelector('.demander-numero-tel') && event.target.form.querySelector('.demander-numero-tel').style.display !== 'none')) {

      let info = event.target.form.querySelector(".alert-info-tel");

      let phoneInputField = event.target.form.querySelector(".input-phone-intelligent");

      let phoneInput = intlTelInput(phoneInputField, {
       initialCountry: "fr",
       geoIpLookup: getIp,
       onlyCountries: ["fr"],
       utilsScript:
         "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });

      let phoneNumber = phoneInput.getNumber();
      let numberType = phoneInput.getNumberType();
      info.style.display = "none";

      if (phoneInput.isValidNumber() && numberType === intlTelInputUtils.numberType.MOBILE) {
        console.log("working");
        // submitButtonTel.removeAttribute("disabled");


        let allAreFilled = true;
        event.target.form.querySelectorAll("[required]").forEach(function(i) {
          if (i.name !== "meeting[time_start]" && i.name !== "premeeting[time_start]") {
            if (!allAreFilled) return;
            if (i.type === "radio") {
              let radioValueCheck = false;
              event.target.form.querySelectorAll(`[name=${i.name}]`).forEach(function(r) {
                if (r.checked) radioValueCheck = true;
              })
              allAreFilled = radioValueCheck;
              return;
            }
            if (!i.value) { allAreFilled = false;  return; }
          }
        })
        if (!allAreFilled) {
          // alert('Fill all the fields');
        } else {
          phoneInputField.value = phoneNumber.substring(1)
          // phoneInputField.removeAttribute("pattern")
        }


        event.target.click();

         // info.style.display = "";
         // info.innerHTML = `Phone number in E.164 format: <strong>${phoneNumber}</strong>`;
      } else {
        console.log("not working");
        info.style.display = "";
        if (phoneInput.isValidNumber()) {
          info.innerHTML = `Le numéro de téléphone doit être un numéro de portable.`;
        } else {
          info.innerHTML = `Le numéro de téléphone est invalide.`;
        }

        let allAreFilled = true;
        event.target.form.querySelectorAll("[required]").forEach(function(i) {
          if (i.name !== "meeting[time_start]" && i.name !== "premeeting[time_start]") {
            if (!allAreFilled) return;
            if (i.type === "radio") {
              let radioValueCheck = false;
              event.target.form.querySelectorAll(`[name=${i.name}]`).forEach(function(r) {
                if (r.checked) radioValueCheck = true;
              })
              allAreFilled = radioValueCheck;
              return;
            }
            if (!i.value) { allAreFilled = false;  return; }
          }
        })
        if (!allAreFilled) {
          // alert('Fill all the fields');
        } else {
          event.preventDefault();
          return false;
        }


        // submitButtonTel.setAttribute("disabled", "");

      }
    }
  }
};


export { processTelephoneInput };

