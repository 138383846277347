const readURL = (input) => {
  const file = input.files[0];
  const maxSize = 10485760; // 10 MB en octets
  const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/bmp']; // Types autorisés

  if (!allowedTypes.includes(file.type)) {
    alert("Veuillez sélectionner une image de type jpeg, jpg, png, webp, ou bmp.");
    input.value = ""; // Réinitialiser l'input
    return;
  }
  
  if (file.size > maxSize) {
    alert("La taille du fichier dépasse la limite de 10 MB. Veuillez choisir un autre fichier.");
    input.value = ""; // Réinitialiser l'input
  } else {
    // Appeler la fonction readURL pour afficher l'aperçu si nécessaire
    const imagePreview = document.querySelector('#img_prev');
    imagePreview.style.display = "";
    if (document.querySelector('#avatar-remove')) {
      const avatarImage = document.querySelector('#avatar-remove');
      avatarImage.style.display ="none";
    }
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#img_prev')
          .attr('src', e.target.result)
          .width(45)
          .height(45);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
}

export { readURL };
