const selectAllForm = () => {
  if (document.querySelector('#select-all')) {
    const link = document.querySelector('#select-all');
    link.addEventListener('click', event => {
      document.querySelectorAll(".form-check-input").forEach(function(element) {
        element.checked = true;
      });
    });
  }
  if (document.querySelector('#not-select-all')) {
    const link2 = document.querySelector('#not-select-all');
    link2.addEventListener('click', event => {
      document.querySelectorAll(".form-check-input").forEach(function(element) {
        element.checked = false;
      });
    });
  }
  if (document.querySelector('#select-all-smartphone')) {
    const link3 = document.querySelector('#select-all-smartphone');
    link3.addEventListener('click', event => {
      document.querySelectorAll(".form-check-input").forEach(function(element) {
        element.checked = true;
      });
    });
  }
  if (document.querySelector('#not-select-all-smartphone')) {
    const link4 = document.querySelector('#not-select-all-smartphone');
    link4.addEventListener('click', event => {
      document.querySelectorAll(".form-check-input").forEach(function(element) {
        element.checked = false;
      });
    });
  }
}

export { selectAllForm };
