import { Controller } from "stimulus";

export default class extends Controller {
  // Afficher le GIF de chargement
  showLoading() {
    const loadingMessage = document.getElementById("loading-message");
    const resultatSearch = document.getElementById("similarity_results");
    if (loadingMessage) {
      loadingMessage.style.display = "block";
      resultatSearch.style.display = "none";
    }
  }

  // Masquer le GIF de chargement
  hideLoading() {
    const loadingMessage = document.getElementById("loading-message");
    const resultatSearch = document.getElementById("similarity_results");
    if (loadingMessage) {
      loadingMessage.style.display = "none";
      resultatSearch.style.display = "block";
    }
  }
}
