import places from 'places.js';

const initAutocomplete = () => {
  const addressInput = document.querySelector('.city-search-input');
  if (addressInput) {
    places({ container: addressInput });
  }
  const addressInputSmartphone = document.querySelector('.city-search-input-smartphone');
  if (addressInputSmartphone) {
    places({ container: addressInputSmartphone });
  }
  const addressInputId = document.getElementById('city-address');
  if (addressInputId) {
    places({ container: addressInputId });
  }
  const addressInputId2 = document.getElementById('city-address-2');
  if (addressInputId2) {
    places({ container: addressInputId2 });
  }
  const addressInputId3 = document.getElementById('city-address-3');
  if (addressInputId3) {
    places({ container: addressInputId3 });
  }
  const addressInputIdSmartphone = document.getElementById('city-address-smartphone');
  if (addressInputIdSmartphone) {
    places({ container: addressInputIdSmartphone });
  }
  const addressInputIdSmartphone2 = document.getElementById('city-address-smartphone-2');
  if (addressInputIdSmartphone2) {
    places({ container: addressInputIdSmartphone2 });
  }
  const addressInputNavbar = document.querySelector('.city-search-input-navbar');
  if (addressInputNavbar) {
    places({ container: addressInputNavbar });
  }
  const addressInputNavbarSmartphone = document.querySelector('.city-search-input-navbar-smartphone');
  if (addressInputNavbarSmartphone) {
    places({ container: addressInputNavbarSmartphone });
  }
  const addressInputCallToAction = document.querySelector('.city-search-input-call-to-action');
  if (addressInputCallToAction) {
    places({ container: addressInputCallToAction });
  }
  const addressInputCallToActionSmartphone = document.querySelector('.city-search-input-call-to-action-smartphone');
  if (addressInputCallToActionSmartphone) {
    places({ container: addressInputCallToActionSmartphone });
  }
};


export { initAutocomplete };
