const showEditComment = () => {
  if (document.querySelector('#edit-comment-show')) {
    const link = document.querySelector('#edit-comment-show');
    const editSection = document.querySelector('#edit-comment-hidden');
    link.addEventListener('click', event => {
      link.style.display = "none";
      editSection.style.display = "";
    });
  }
  if (document.querySelector('#edit-comment-show-smartphone')) {
    const link = document.querySelector('#edit-comment-show-smartphone');
    const editSection = document.querySelector('#edit-comment-hidden-smartphone');
    link.addEventListener('click', event => {
      link.style.display = "none";
      editSection.style.display = "";
    });
  }
}

export { showEditComment };
