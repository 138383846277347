 import { EmojiButton } from '@joeattardi/emoji-button';



 const initEmojiButton = () => {
   const picker = new EmojiButton();
   const triggers = document.querySelectorAll(".emoji-trigger");
   for ( var j = 0; j < triggers.length; j++ ) (function(j){
     const trigger = document.querySelector('.emoji-trigger');

     picker.on('emoji', selection => {
       // handle the selected emoji here
       console.log(selection.emoji);
       document.querySelector("trix-editor").value += selection.emoji;
       // document.querySelector("trix-editor").focus();
     });

     trigger.addEventListener('click', () => picker.togglePicker(trigger));
     // document.querySelector("trix-editor").focus();
   })(j);
 };

 export { initEmojiButton };
