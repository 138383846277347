import intlTelInput from 'intl-tel-input';


const initIntelTelephone = () => {
  if (document.querySelector(".form-phone-intelligent")) {
    function getIp(callback) {
     fetch('https://ipinfo.io/json?token=<votre token>', { headers: { 'Accept': 'application/json' }})
       .then((resp) => resp.json())
       .catch(() => {
         return {
           country: 'fr',
         };
       })
       .then((resp) => callback(resp.country));
    }


    const formPhoneIntelligents = document.querySelectorAll(".form-phone-intelligent");
    for ( var i = 0; i < formPhoneIntelligents.length; i++ ) (function(i){

      let phoneInputField = formPhoneIntelligents[i].querySelector(".input-phone-intelligent");

      let phoneInput = intlTelInput(phoneInputField, {
       initialCountry: "fr",
       geoIpLookup: getIp,
       onlyCountries: ["fr"],
       utilsScript:
         "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });

      let info = formPhoneIntelligents[i].querySelector(".alert-info-tel");

      let submitButtonTel = formPhoneIntelligents[i].querySelector(".submit-phone-intelligent");


    })(i);

  }


};


export { initIntelTelephone };

