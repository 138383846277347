const showPasswordDevise = () => {
  if (document.querySelectorAll(".div-password-eye")) {
    const passwordDiv = document.querySelectorAll(".div-password-eye");
    for ( var i = 0; i < passwordDiv.length; i++ ) (function(i){
      let togglePassword = passwordDiv[i].querySelector(".togglePassword");
      let password = passwordDiv[i].querySelector(".password-eye-valid");
      if (togglePassword) {
        togglePassword.addEventListener("click", function () {
            // toggle the type attribute
            let type = password.getAttribute("type") === "password" ? "text" : "password";
            password.setAttribute("type", type);

            // toggle the icon
            this.classList.toggle('fa-eye-slash');
        });
      }
    })(i);
  }
}

export { showPasswordDevise };
