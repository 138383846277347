function openConnexion() {
  var connexions = document.getElementsByClassName("inscription-modal");
  for(var i = 0; i < connexions.length; i++){
    connexions[i].style.display = "none";
  }
  var newRegistrations = document.getElementsByClassName("new-registration-modal");
  for(var i = 0; i < newRegistrations.length; i++){
    newRegistrations[i].style.display = "none";
  }
  var newSessions = document.getElementsByClassName("new-session-modal");
  for(var i = 0; i < newSessions.length; i++){
    newSessions[i].style.display = "none";
  }
  var inscriptions = document.getElementsByClassName("connexion-modal");
  for(var i = 0; i < inscriptions.length; i++){
    inscriptions[i].style.display = "";
  }
}

function openInscription() {
  var connexions = document.getElementsByClassName("connexion-modal");
  for(var i = 0; i < connexions.length; i++){
    connexions[i].style.display = "none";
  }
  var newRegistrations = document.getElementsByClassName("new-registration-modal");
  for(var i = 0; i < newRegistrations.length; i++){
    newRegistrations[i].style.display = "none";
  }
  var newSessions = document.getElementsByClassName("new-session-modal");
  for(var i = 0; i < newSessions.length; i++){
    newSessions[i].style.display = "none";
  }
  var inscriptions = document.getElementsByClassName("inscription-modal");
  for(var i = 0; i < inscriptions.length; i++){
    inscriptions[i].style.display = "";
  }
}

const openNavbarModal = () => {

  if (document.querySelectorAll(".open-connexion-class")) {
    const openConnexionJs = document.querySelectorAll(".open-connexion-class");
    for ( var j = 0; j < openConnexionJs.length; j++ ) (function(j){
      openConnexionJs[j].addEventListener('click', event => {
        openConnexion();
      });
    })(j);
  }
  if (document.querySelectorAll(".open-inscription-class")) {
    const openInscriptionJs = document.querySelectorAll(".open-inscription-class");
    for ( var j = 0; j < openInscriptionJs.length; j++ ) (function(j){
      openInscriptionJs[j].addEventListener('click', event => {
        openInscription();
      });
    })(j);
  }

}

export { openNavbarModal };
