import consumer from "./consumer";


const scrollToBottom = () => {
  if (document.getElementById('messages')) {
    const messages = document.getElementById('messages');
    messages.scrollTop = messages.scrollHeight;
  }
}

const initChatroomCable = () => {

  const messagesContainer = document.getElementById('messages');

  if (messagesContainer) {
    const id = messagesContainer.dataset.chatroomId;

    consumer.subscriptions.create({ channel: "ChatroomChannel", id: id }, {
      received(data) {
        messagesContainer.insertAdjacentHTML('beforeend', data);
        scrollToBottom();
        // $('#message_content').val('');
        $("#messages").animate({ scrollTop: 20000000 }, "slow");      },
    });
  }
}

export { initChatroomCable };

