import { Controller } from 'stimulus';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

export default class extends Controller {
  static values = { apiKey: String }

  static targets = ["address"]

  connect() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      language: "fr",
      country: "fr",
      placeholder: this.data.get("placeholderValue"),
      types: "country,region,place,postcode,locality,neighborhood,address"
    });
    this.geocoder.addTo(this.element)
    // partie pour que l'input affiche la valeur s'il y en a déjà une (form edit doctor par exemple)
    if (this.addressTarget.value !== "") {
      this.element.querySelector('.mapboxgl-ctrl-geocoder--input').value = this.addressTarget.value;
    }
    // fin de la partie pour que l'input affiche la valeur s'il y en a déjà une (form edit doctor par exemple)

    this.geocoder.on("result", event => this.setInputValue(event))
    this.geocoder.on("clear", () => this.clearInputValue())


    if (this.data.get("addClassValue")) {
      this.element.querySelector('.mapboxgl-ctrl-geocoder--input').classList.add(this.data.get("addClassValue")+"input");
      this.element.querySelector('.mapboxgl-ctrl-geocoder').classList.add(this.data.get("addClassValue"));
    }
    if (this.data.get("requiredValue") === "true") {
      this.element.querySelector('.mapboxgl-ctrl-geocoder--input').setAttribute('required', '');
    }
    if (this.data.get("placeholderValue") === "false") {
      this.element.querySelector('.mapboxgl-ctrl-geocoder--input').setAttribute('placeholder', '');
    }
  }
  setInputValue(event) {
    this.addressTarget.value = event.result["place_name"]
  }

  clearInputValue() {
    this.addressTarget.value = ""
  }
  // replace() {
  //   console.log("click")
  // }
}
