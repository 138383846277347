 function insertTags (texte, inputTag) {
   if (inputTag.value.includes(',' + texte.innerHTML)) {
    inputTag.value = remove_character((',' + texte.innerHTML), inputTag.value);
    texte.style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #f4f4f4;color:black;";
   } else if (inputTag.value.includes(texte.innerHTML)) {
    inputTag.value = remove_character(texte.innerHTML, inputTag.value);
    texte.style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #f4f4f4;color:black;";
   }
   else if (inputTag.value === '') {
    inputTag.value = texte.innerHTML;
    texte.style.cssText = "cursor:pointer;background-color:#4b4b4b;border:1px solid #4b4b4b;color:white;";
   } else {
    inputTag.value = inputTag.value + ',' + texte.innerHTML;
    texte.style.cssText = "cursor:pointer;background-color:#4b4b4b;border:1px solid #4b4b4b;color:white;";
   }
   if (document.querySelector('.section-edit-user')) {
    document.getElementById('submit-tags').click();
   }
 }

 function insertColorfulTags (texte, inputTag) {
   if (inputTag.value.includes(',' + texte.innerHTML)) {
    inputTag.value = remove_character((',' + texte.innerHTML), inputTag.value);
    texte.classList.remove(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.add(`tag-at-first`);
   } else if (inputTag.value.includes(texte.innerHTML)) {
    inputTag.value = remove_character(texte.innerHTML, inputTag.value);
    texte.classList.remove(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.add(`tag-at-first`);
   }
   else if (inputTag.value === '') {
    inputTag.value = texte.innerHTML;
    texte.classList.add(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.remove(`tag-at-first`);
   } else {
    inputTag.value = inputTag.value + ',' + texte.innerHTML;
    texte.classList.add(`expertise-tag-${texte.innerHTML.split(" ")[1]}`);
    texte.classList.remove(`tag-at-first`);
   }
   if (document.querySelector('.section-edit-user')) {
    document.getElementById('submit-tags').click();
   }
 }


 function remove_character(str_to_remove, str) {
   let reg = new RegExp(str_to_remove)
   return str.replace(reg, '')
 }


const clickEventTag = () => {
  const competenceTags = document.querySelectorAll(".competencetag");
  if (document.querySelector('.section-edit-user')) {
    if ((window.innerWidth > 575.98 ) && (document.querySelectorAll(".category-input")))  {
      const categoryInputs = document.querySelectorAll(".category-input");
      for ( var j = 0; j < categoryInputs.length; j++ ) (function(j){
        for ( var i = 0; i < competenceTags.length; i++ ) (function(i){
          competenceTags[i].onclick = function() {
           competenceTags[i].addEventListener('click', insertTags(competenceTags[i], categoryInputs[j]));
          }     // competenceTags[i].onclick = function() {
         //   removeTags(this.innerHTML);
         //   competenceTags[i].style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #4b4b4b;color:black;";
         //  }
       })(i);
      })(j);
    }
    else {
      const categoryInputs = document.querySelectorAll(".category-input");
      for ( var j = 0; j < categoryInputs.length; j++ ) (function(j){
        for ( var i = 0; i < competenceTags.length; i++ ) (function(i){
          competenceTags[i].ontouchstart = function() {
           competenceTags[i].addEventListener('touchstart', insertTags(competenceTags[i], categoryInputs[j]));
          }     // competenceTags[i].onclick = function() {
         //   removeTags(this.innerHTML);
         //   competenceTags[i].style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #4b4b4b;color:black;";
         //  }
       })(i);
      })(j);
    }
  }
  else {
    if ((window.innerWidth > 575.98 ) && (document.querySelectorAll(".category-input")))  {
      const categoryInputs = document.querySelectorAll(".category-input");
      for ( var j = 0; j < categoryInputs.length; j++ ) (function(j){
        for ( var i = 0; i < competenceTags.length; i++ ) (function(i){
          competenceTags[i].onclick = function() {
           competenceTags[i].addEventListener('click', insertColorfulTags(competenceTags[i], categoryInputs[j]));
          }     // competenceTags[i].onclick = function() {
         //   removeTags(this.innerHTML);
         //   competenceTags[i].style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #4b4b4b;color:black;";
         //  }
       })(i);
      })(j);
    }
    else if (document.querySelectorAll(".category-input-mobile")) {
      const categoryInputMobiles = document.querySelectorAll(".category-input-mobile");
      for ( var j = 0; j < categoryInputMobiles.length; j++ ) (function(j){
        for ( var i = 0; i < competenceTags.length; i++ ) (function(i){
         competenceTags[i].ontouchstart = function() {
          competenceTags[i].addEventListener('touchstart', insertColorfulTags(competenceTags[i], categoryInputMobiles[j]));
         }
         // competenceTags[i].onclick = function() {
         //   removeTags(this.innerHTML);
         //   competenceTags[i].style.cssText = "cursor:pointer;background-color:#f4f4f4;border:1px solid #4b4b4b;color:black;";
         //  }
       })(i);
      })(j);
    }
  }
}

export { clickEventTag };
