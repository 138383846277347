import Rails from "@rails/ujs";
window.Rails = Rails;
import { initMapbox } from '../plugins/init_mapbox';


const filterDoctors = () => {
  if (window.innerWidth > 575.98 && (document.querySelector('#filter'))) {
    var specialties = document.querySelectorAll('.select-items');
    // var city = document.querySelector('.specialty-custom');
    var form = document.querySelector('#filter');

    for (const check of specialties) {
    check.addEventListener( 'click', function() {
      Rails.fire(form, 'submit');
      });
    }
    initMapbox();
  }
  else if (document.querySelector('#filter2')) {
    var specialties = document.querySelectorAll('.select-items');
    // var city = document.querySelector('.city-smartphone');
    var form = document.querySelector('#filter2');

    for (const check of specialties) {
    check.addEventListener( 'click', function() {
      Rails.fire(form, 'submit');
      });
    }
    initMapbox();
  }
}


export { filterDoctors };
