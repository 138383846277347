const geoFindMe = () => {
  // const citySearchInput = document.querySelector('.city-search-geo');
  const citySearchInput = document.querySelector('#queries_city');
  const mapboxSearchInput = document.querySelector('.mapboxgl-ctrl-geocoder--input');
  const locSearchInput = document.querySelector('.loc-search-geo');

  function success(position) {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    citySearchInput.value = "Lieu actuel";
    mapboxSearchInput.value = "Lieu actuel";
    citySearchInput.classList.add("myposition");
    locSearchInput.value = "[" + latitude + ", " + longitude + "]";
    // document.getElementById('form-geolocation').submit();

  }

  function error() {
    alert("La géolocalisation n'est pas supportée par votre navigateur internet, ou elle n'est pas activée.");
  }

  if(!navigator.geolocation) {
    alert("La géolocalisation n'est pas supportée par votre navigateur internet.");
  } else {
    // citySearchInput.value = 'Locating…';
    navigator.geolocation.getCurrentPosition(success, error);
  }
}

const geoFindMeMobile = (evt) => {
  evt.preventDefault();
  const citySearchInputMobile = document.querySelector('#queries_city');
  // const citySearchInputMobile = document.querySelector('.city-search-geo-mobile');
  const mapboxSearchInputMobile = document.querySelector('.mapboxgl-ctrl-geocoder--input');
  const locSearchInputMobile = document.querySelector('.loc-search-geo-mobile');

  function success(position) {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    citySearchInputMobile.value = "Lieu actuel";
    mapboxSearchInputMobile.value = "Lieu actuel";
    citySearchInputMobile.classList.add("myposition");
    locSearchInputMobile.value = "[" + latitude + ", " + longitude + "]";
    // document.getElementById('form-geolocation-mobile').submit();
  }

  function error() {
    alert("Veuillez activer la géolocalisation dans vos réglages.");
  }

  if(!navigator.geolocation) {
    alert("La géolocalisation n'est pas supportée par votre navigateur internet.");
  } else {
    // citySearchInput.value = 'Locating…';
    navigator.geolocation.getCurrentPosition(success, error);
  }
}

const geoSearch = () => {
  if (document.querySelector('#geolocation-logo')) {
    document.querySelector('#geolocation-logo').addEventListener('click', geoFindMe);
  }
  if (document.querySelector('#geolocation-logo-mobile')) {
    $('#geolocation-logo-mobile').bind('touchstart', geoFindMeMobile);
    // document.querySelector('#geolocation-logo-mobile').addEventListener('touchstart', geoFindMeMobile, false);
  }
}

export { geoSearch };


