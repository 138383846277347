const loadMapAndShowInfos = () => {
  if (document.querySelector(".informations-pratiques") && document.querySelector('.load-map-link')) {
    document.querySelector(".informations-pratiques").addEventListener( 'click', function() {
      document.querySelector('.load-map-link').click();
      if (document.querySelector(".informations-pratiques").classList.contains("collapsed")) {
        document.querySelector(".informations-pratiques").classList.toggle("collapsed");
        document.querySelector(".show-infos").classList.toggle("collapsed");
        document.querySelector(".hidden-informations-pratiques").classList.toggle("collapse");
      } else {
        document.querySelector(".informations-pratiques").classList.toggle("collapsed");
        document.querySelector(".show-infos").classList.toggle("collapsed");
        document.querySelector(".hidden-informations-pratiques").classList.toggle("collapse");
      }
    });
  }
}

export { loadMapAndShowInfos };
