require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'controllers'
import "bootstrap";
import "../plugins/flatpickr"
import "chartkick/chart.js"
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';
import { tns } from "tiny-slider/src/tiny-slider"
import { initMapbox } from '../plugins/init_mapbox';
import { initEmojiButton } from '../plugins/emoji_button';
import { initAutocomplete } from '../plugins/init_autocomplete';
import { initAlgoliaSearch } from '../plugins/init_algolia_search';
import { initIntelTelephone } from '../plugins/create_intl_input_form';
import { processTelephoneInput } from '../plugins/process_telephone_intl_input';
import { initAhoyClickTracking } from '../plugins/ahoy_track_clicks';
import { showEditComment } from '../components/init_edit_comment';
import { readURL } from '../components/preview_image';
import { readBackgroundURL } from '../components/preview_background_image_doctor';
import { readBadgeAssociationURL } from '../components/preview_badge_association_doctor';
import { selectAllForm } from '../components/init_select_all';
import { openNavbarModal } from '../components/open_navbar_modal';
import { initChatroomCable } from '../channels/chatroom_channel';
import { initGroupCable } from '../channels/group_channel';
import { initNotificationForUserCable } from '../channels/notification_for_user_channel';
// import { initNotificationCable } from '../channels/notification_channel';
import { scrollToBottom } from '../components/scroll_last_message';
import { navbarHomeScroll } from '../components/navbar_home_scroll';
import { openSectionsNav } from '../components/open_sections_in_nav_mobile';
import { loadMapAndShowInfos } from '../components/load_map_and_show_infos_doc_show';
import { modalsShow } from '../components/modals_show';
import { loadMeetingsWithClick } from '../components/load_meetings_with_click'
import { geoSearch } from '../components/geolocation';
// import { villeGeoSearch } from '../components/ville_geolocation';
import { French } from "flatpickr/dist/l10n/fr.js"
// import { displayTagsAdded } from '../components/selected_tags_edit_page';
import { clickEventTag } from '../components/choose_tags';
import { clickEventTagMultiple } from '../components/choose_tags_multiple_doctors';
import { filterDoctors } from '../components/filter_doctors.js';
import { showPasswordDevise } from '../components/show_password_devise';
import { copyClipboardLinks } from '../components/copy_clipboard_links';
import { updateMeetingNotes } from '../components/update_meeting_notes';
// import { clearInputAjax } from '../components/clear_input_ajax';


document.addEventListener('turbolinks:load', () => {
  // j'avais rajouté ça pour tester si changement déconnexion
  // Rails.refreshCSRFTokens();
  // fin j'avais rajouté ça pour tester si changement déconnexion
  initChatroomCable();
  initGroupCable();
  // initNotificationCable();
  initNotificationForUserCable();
  initAhoyClickTracking();
  window.initAhoyClickTracking = initAhoyClickTracking;
  scrollToBottom();
  window.scrollToBottom = scrollToBottom;
  window.initGroupCable = initGroupCable;
  initAutocomplete();
  initAlgoliaSearch();
  initIntelTelephone();
  window.initIntelTelephone = initIntelTelephone;
  processTelephoneInput();
  window.processTelephoneInput = processTelephoneInput;
  // partie pour charger algolia quand input loaded via stimulus content loader
  window.initAlgoliaSearch = initAlgoliaSearch;
  // fin de partie pour charger algolia quand input loaded via stimulus content loader
  initMapbox();
  openSectionsNav();
  navbarHomeScroll();
  loadMapAndShowInfos();
  modalsShow();
  loadMeetingsWithClick();
  window.initMapbox = initMapbox;
  window.tns = tns;
  showEditComment();
  initEmojiButton();
  window.initEmojiButton = initEmojiButton;
  window.readURL = readURL;
  window.readBackgroundURL = readBackgroundURL;
  window.readBadgeAssociationURL = readBadgeAssociationURL;
  // ligne rajoutée pour que ça scroll en haut à chaque nouvel page, sinon ça crée des bugs avec turbolings sur rails 6
  window.scrollTo(0, 0);
  // fin de la ligne rajoutée pour que ça scroll en haut à chaque nouvel page, sinon ça crée des bugs avec turbolings sur rails 6
  selectAllForm();
  openNavbarModal();
  geoSearch();
  // villeGeoSearch();
  // displayTagsAdded();
  clickEventTag();
  clickEventTagMultiple();
  filterDoctors();
  updateMeetingNotes();
  window.updateMeetingNotes = updateMeetingNotes;
  // clearInputAjax();
  copyClipboardLinks();
  showPasswordDevise();
  flatpickr(".datepicker", {
    "locale": French,
    altInput: true,
    altFormat: "j F Y",
    // dateFormat: "d.m.Y",
    maxDate: "today"
  });
  flatpickr(".daterdv", {
    "locale": French,
    altInput: true,
    altFormat: "j F Y",
    // dateFormat: "d.m.Y",
    minDate: "today"
  });
  // ahoy.trackClicks();
});


var Trix = require("trix")
// require("trix")
require("@rails/actiontext")
// console.log("Config", Trix.config);

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}
Trix.config.textAttributes.underline = {
  tagName: 'u'
}


const {lang} = Trix.config;

Trix.config.toolbar = {
  getDefaultHTML() { return `\
<div class="trix-button-row">
  <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
    <button type="button" class="trix-button  " data-trix-attribute="underline" title="h3" tabindex="-1">u</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
  </span>
  <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
    <button type="button" class="trix-button  " data-trix-attribute="heading1" title="h1" tabindex="-1">h1</button>
    <button type="button" class="trix-button  " data-trix-attribute="heading2" title="h2" tabindex="-1">h2</button>
    <button type="button" class="trix-button  " data-trix-attribute="heading3" title="h3" tabindex="-1">h3</button>
    <button type="button" class="trix-button laptop-only emoji-trigger" data-trix-attribute="emoji" title="emo" tabindex="-1">😀</button>

    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
  </span>
  <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
  </span>
  <span class="trix-button-group-spacer"></span>
  <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
  </span>
</div>
<div class="trix-dialogs" data-trix-dialogs>
  <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
    <div class="trix-dialog__link-fields">
      <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
      <div class="trix-button-group">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
      </div>
    </div>
  </div>
</div>\
`; }
};
