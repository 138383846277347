function clickOnAjaxForm(input) {
  console.log("heybd");
  setTimeout(function(){
    input.querySelector('.input-notes-meeting-submit').click();
  }, 300);

}


const updateMeetingNotes = () => {

  const meetingNotesInput = document.querySelectorAll(".input-notes-meeting");

  for ( var j = 0; j < meetingNotesInput.length; j++ ) (function(j){
    meetingNotesInput[j].querySelector('trix-editor').addEventListener("keydown", event => { clickOnAjaxForm(meetingNotesInput[j])});
  })(j);


}

export { updateMeetingNotes };
