const navbarHomeScroll = () => {
  if (document.querySelector('.banner-connexion-trouver-medecin')) {
    $('.navbar').addClass('home');
    $(window).scroll(function(){
      $('.navbar.home').toggleClass('scrolled', $(this).scrollTop() > 450);
    });
  }
}

export { navbarHomeScroll };
