import ahoy from "ahoy.js";


const initAhoyClickTracking = () => {
  if (document.querySelector('.ahoy-click-via-js')) {
    const triggers = document.querySelectorAll(".ahoy-click-via-js");
    for ( var j = 0; j < triggers.length; j++ ) (function(j){
      triggers[j].addEventListener("click", function(){
        var propertiesAhoy = new Object();
        if (triggers[j].dataset.nameEvent) {
          propertiesAhoy.click_on = triggers[j].dataset.nameEvent;
        }
        if (triggers[j].dataset.roomId) {
          propertiesAhoy.room_id = triggers[j].dataset.roomId;
        }
        if (triggers[j].dataset.userStatut) {
          propertiesAhoy.user_statut = triggers[j].dataset.userStatut;
        }
        ahoy.track('clicks-js', propertiesAhoy);
        // pour tracker tous les datasets automatiquement
        // ahoy.track('clicks-js', triggers[j].dataset);
        // fin pour tracker tous les datasets automatiquement
      });
    })(j);
  }
  if (document.querySelector('.ahoy-track-page-view')) {
    const pageToTrack = document.querySelectorAll(".ahoy-track-page-view");
    for ( var j = 0; j < pageToTrack.length; j++ ) (function(j){
      if (pageToTrack[j].dataset.done) {
      } else {
        ahoy.track(pageToTrack[j].dataset.name, pageToTrack[j].dataset.properties);
        pageToTrack[j].dataset.done = "done";
      }
    })(j);

  }
};

export { initAhoyClickTracking };


