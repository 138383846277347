const scrollToBottom = () => {
  if (document.getElementById('messages')) {
    const messages = document.getElementById('messages');
    messages.scrollTop = messages.scrollHeight;
  }
  if (document.getElementById('message_headers')) {
    const messageheaders = document.getElementById('message_headers');
    messageheaders.scrollTop = messageheaders.scrollHeight;
  }
}

export { scrollToBottom };


