import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.load()
  }

  load() {
    // fetch(this.urlVaue, { headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }})
    fetch(this.urlValue)
      .then(response => response.text())
      // .then(html => this.element.innerHTML = html)
      .then(html => {
        this.element.innerHTML = html
        this.loadMore(this.element);
      })
  }

  loadMore(containerElement) {
    const scriptElements = containerElement.querySelectorAll("script");

    Array.from(scriptElements).forEach((scriptElement) => {
      const clonedElement = document.createElement("script");

      Array.from(scriptElement.attributes).forEach((attribute) => {
        clonedElement.setAttribute(attribute.name, attribute.value);
      });

      clonedElement.text = scriptElement.text;

      scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
    });
  }
}
