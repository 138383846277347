import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(this.loadMore.bind(this), {
      rootMargin: '200px', // Ajustez cette valeur pour charger plus tôt
      threshold: 0.5
    });

    this.observer.observe(this.triggerElement);
  }

  disconnect() {
    this.observer.disconnect();
  }

  loadMore(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let nextPage = this.triggerElement.dataset.nextPage;
        let url = this.triggerElement.dataset.url;
        let csrfToken = document.querySelector("[name='csrf-token']").content;

        fetch(url, {
          headers: {
            "Accept": "text/javascript",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin" // This is important for including the CSRF token with the request
        })
        .then(response => response.text())
        .then(html => {
          document.getElementById('groups-container').insertAdjacentHTML('beforeend', html);
          if (this.triggerElement.dataset.nextPage && !document.querySelector('.end-scroll')) {
            this.triggerElement.dataset.nextPage = parseInt(nextPage) + 1;
            this.triggerElement.dataset.url = url.replace(/page=\d+/, 'page=' + this.triggerElement.dataset.nextPage);
            const loadingCards = document.getElementById("loading-cards-group");
            document.getElementById('groups-container').appendChild(loadingCards);
          } else {
            this.observer.unobserve(this.triggerElement);
            this.triggerElement.remove();
            document.getElementById("loading-cards-group").remove();
          }
        });
      }
    });
  }


  get triggerElement() {
    return this.element.querySelector('#infinite-scroll-trigger');
  }
}
