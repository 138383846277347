import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination"]


  // scroll() {
  //   console.log(document.querySelector("#message_headers"));
  //   const scroller = document.querySelector("#message_headers");

  //   scroller.addEventListener("scroll", (event) => {
  //     if (scroller.scrollTop === 0) {
  //       this.loadMore()
  //     }
  //   });
  // }

  initialize() {
     let options = {
       rootMargin: '0px',
       threshold: 1.0
     }
     console.log(this);

     this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
   }


   connect() {
     this.intersectionObserver.observe(this.paginationTarget)
   }

   disconnect() {
     this.intersectionObserver.unobserve(this.paginationTarget)
   }

   processIntersectionEntries(entries) {
     entries.forEach(entry => {
       if (entry.isIntersecting) {
          const scroller = document.querySelector("#message_headers");

          scroller.addEventListener("scroll", (event) => {
            if (scroller.scrollTop === 0) {
              this.loadMore()
            }
          });
         // this.loadMore()
       }
     })
   }


  loadMore() {
    let next_page = this.paginationTarget.querySelector("a")
    if (next_page == null) { return }
    let url = next_page.href

    next_page.click();

    // Rails.ajax({
    //   type: 'GET',
    //   url: url,
    //   dataType: 'json',
    //   success: (data) => {
    //     this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
    //     this.paginationTarget.innerHTML = data.pagination
    //   }
    // })
  }
}
