import consumer from "./consumer";


// const scrollToBottom = () => {
//   if (document.getElementById('messages')) {
//     const messages = document.getElementById('messages');
//     messages.scrollTop = messages.scrollHeight;
//   }
// }


const initNotificationCable = () => {
  // const messageheadersContainer = document.getElementById('message_headers');

  // if (messageheadersContainer) {

  //   consumer.subscriptions.create({ channel: "NotificationChannel", id: id }, {
  //     received(data) {
  //       console.log(data);

  //     },
  //   });
  // }
}

export { initNotificationCable };

