const readBadgeAssociationURL = (input) => {
  const imagePreview = document.querySelector('#badge_prev');
  const avatarImage = document.querySelector('#badge-remove');
  imagePreview.style.display = "";
  if (document.querySelector('#badge-remove')) {
    avatarImage.style.display ="none";
  }
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#badge_prev')
        .attr('src', e.target.result)
        .width(45)
        .height(45);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

export { readBadgeAssociationURL };
