const openSectionsNav = () => {
  if (document.querySelectorAll(".container-title-nav") && document.querySelectorAll(".nav-sublinks")) {
    const navTitles = document.querySelectorAll(".container-title-nav");
    const subContainer = document.querySelectorAll(".nav-sublinks");
    for ( var j = 0; j < navTitles.length; j++ ) (function(j){
      navTitles[j].onclick = function() {
        if (subContainer[j].style.display === "none") {
          subContainer[j].style.display = "block";
          // console.log("aaa");
          // console.log(navTitles[j]);
          // console.log(navTitles[j].getElementsByClassName("fleche-bas-css")[0]);
          // navTitles[j].getElementsByClassName("fleche-bas-css")[0].className = "fleche-haut-css";
        } else {
          subContainer[j].style.display = "none";
        }
      }
    })(j);
  }
}

export { openSectionsNav };
