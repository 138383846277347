import consumer from "./consumer";

const initNotificationForUserCable = () => {
  const notifsContainer = document.getElementById('notificationscontainer');
  const channelsContainer = document.getElementById('channelscontainer');
  const notifsMobileContainer = document.getElementById('notificationsmobilecontainer');
  const channelsMobileContainer = document.getElementById('channelsmobilecontainer');


  if (notifsContainer) {
    const id = notifsContainer.dataset.userId;

    // var cable
    // if (window.cable === undefined || window.cable === null) {

      // window.cable = consumer.subscriptions.create({ channel: "NotificationForUserChannel", id: id }, {
    consumer.subscriptions.create({ channel: "NotificationForUserChannel", id: id }, {

      received(data) {
        console.log("tessssssttnotifi");
        if (data["type"] === "other") {
          notifsContainer.innerHTML = data["body"];
          if (notifsMobileContainer) {
            notifsMobileContainer.innerHTML = data["body_mobile"];
          }
        } else if (data["type"] === "message") {
          if (channelsContainer) {
            channelsContainer.innerHTML = data["body"];
            if (channelsMobileContainer) {
              channelsMobileContainer.innerHTML = data["body"];
            }
          }
          if (document.querySelector(`.notifs-group-${data["group"]}`)) {
            document.querySelector(`.notifs-group-${data["group"]}`).innerHTML = data["body_notif"];
            // if (document.querySelector(`.notifs-group-${data["group"]} p`)) {
            //   let count = document.querySelector(`.notifs-group-${data["group"]} p`).innerHTML;
            //   count = parseInt(count, 10) + 1
            //   document.querySelector(`.notifs-group-${data["group"]} p`).innerHTML = count;
            // } else {
            //   document.querySelector(`.notifs-group-${data["group"]}`).innerHTML = data["body_notif"]
            // }
          }
        }
      },
    });
    // }

  }
}

export { initNotificationForUserCable };

